@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.solBoxBlue {
  @apply bg-gradient-to-r from-solblue-darker2 via-solblue-2 to-solblue-darker2 text-white shadow-solbluelight rounded-lg dark:from-solblue-dark dark:via-solblue-darker dark:to-solblue-dark dark:shadow-solgray rounded-lg;
}

.solBoxGray {
  @apply bg-solgray-light text-solblue-dark shadow-solbluelight rounded-lg dark:bg-solblue-darker2 dark:text-solblue dark:shadow-solblue-dark;
}

.solBtnGray {
  @apply bg-gradient-to-t from-solgray-dark to-solgray-light text-solblue-darker uppercase py-1 px-4 text-sm rounded-full dark:from-solblue-darker dark:to-solblue-dark dark:text-white dark:shadow-solgray;
}
.solBtnGray:hover {
  @apply bg-gradient-to-t from-solgray-lightest to-solgray-light shadow-solbluelight dark:from-solblue-darker2 dark:to-solblue-darker dark:text-solacid dark:shadow-solbluelight2;
}

.solBtnAcid {
  @apply bg-gradient-to-t from-solacid-dark to-solacid text-solblue-darker uppercase py-1 px-4 text-sm rounded-full shadow-solacidlight;
}
.solBtnAcid:hover {
  @apply bg-gradient-to-t from-solacid to-solacid-light shadow-solaciddark ;
}

.solBtnRewards {
  @apply bg-gradient-to-b from-solgray to-solgray-light text-solblue-darker uppercase py-1 px-4 text-sm rounded-b-lg dark:from-solblue-dark dark:to-solblue-darker dark:text-gray-200;
}
.solBtnRewards:hover {
  @apply bg-gradient-to-t from-solacid to-solacid-light shadow-solbluelight dark:from-solblue-dark dark:to-solblue-darker dark:text-solacid dark:shadow-none;
}

html {
  height: 100%;
  background-color: rgba(83,153,165,1);
}

body {
  margin: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* For pie chart */

:root {
  --size: 150px;
  --bord: 25px;
}

.chart {
  width: var(--size);
  height: var(--size);
  margin: 0em auto;
  border-radius: 50%;
  /* background-image: conic-gradient(#D5E300 var(--value), #103147 var(--value)); */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - var(--bord));
  height: calc(100% - var(--bord));
  background: #F1F1F1;
  border-radius: inherit;
}

p {
  position: relative;
  z-index: 1;
  font-size: 1em;
}
