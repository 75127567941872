.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.item:hover {
  background-color: rgba(0, 0, 0, .1);
}

.clickedItem {
  background-color: rgba(0, 0, 0, .2);
}

.text-danger {
  color: #ff5262 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.score-0 {
  color: #cacaca;
}

.score-1 {
  color: #0091f2;
}

.score-2 {
  color: green;
}
